.FeedBackContainer {
  margin: 10px 150px;
  padding-top: 50px;
  font-family: Poppins;
}
.FeedBackContainer h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
}
.FeedBackText {
  height: 150px;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #616066;
}

@media only screen and (max-width: 700px) {
  .FeedBackContainer {
    margin: 10px;
  }
}
