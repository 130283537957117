.TemplateTwoContainer {
  margin: 0px;
  width: 100%;
}

.TemplateTwoNav {
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #2969e5;
  padding-top: 20px;
}

.TemplateTwoBrand {
  margin-left: 150px;
  margin-right: 100px;
  color: white;
  height: 100%;
}
.TemplateTwoNavLink {
  margin-left: 80px;
  color: #a4d86f;
  height: 100%;
}
.TemplateTwoNavLinkActive {
  color: white;
  padding: 0px 20px;
  border-bottom: 8px solid white;
}
@media only screen and (max-width: 700px) {
  .TemplateTwoBrand {
    margin: 10px;
    padding: 10px;
  }
  .TemplateTwoNavLink {
    margin-left: 10px;
    font-size: 9px;
  }
  .TemplateTwoNavLinkActive {
    padding: 0px 10px;
  }
  .TemplateTwoNav {
    height: 40px;
  }
}
