.TemplateOneContainer {
  display: flex;
  height: 100vh;
  overflow: hidden;
  font-family: Poppins;
}
.TemplateOneSidebar {
  height: 100%;
  background-color: #245fd3;
  flex: 0.2;
  min-width: 250px;
}
.TemplateOneDashBoardBody {
  flex: 0.8;
}
.logo {
  width: 40px;
}

.TemplateOneSidebarLinks {
  width: 100%;
}

.TemplateOneSidebarLinks div {
  padding: 30px 0px 30px 20px;
}

.TemplateOneActive {
  background: rgba(246, 246, 246, 0.2);
  border-left: 5px white solid;
}

.TemplateOneLink {
  text-decoration: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
  /* identical to box height, or 164% */

  letter-spacing: 0.02em;

  /* Text/Light */

  color: #f6f6f6;
}
.link {
  color: white;
  text-decoration: none;
}

.TemplateOneDashBoardBody {
  padding: 50px;
}

.TemplateOneBrandNameContainer {
  padding: 30px 0px 30px 40px;
  color: white;
}

.icon {
  width: 30px;
  padding: 0px 10px 0px 10px;
  margin-top: -10px;
  margin-bottom: -10px;
}

@media only screen and (max-width: 700px) {
  .TemplateOneLink {
    display: none;
    margin: 0px;
    padding: 0px;
  }
  .check {
    display: none;
  }
  .icon {
    width: 20px;
    margin: 0px;
    padding: 0px;
  }
  .logo {
    width: 25px;
    margin: 0px;
    padding: 0px;
  }
  .TemplateOneBrandNameContainer {
    margin: 15px;
    padding: 0px;
  }
  .TemplateOneSidebar {
    min-width: 50px;
  }
  .TemplateOneDashBoardBody {
    padding: 10px;
  }
}
