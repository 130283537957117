.ComplaintDetailsContainer {
  margin: 10px 150px;
  background-color: #eef2f9;
  padding: 20px;
  font-family: Poppins;
}

.ComplaintDetailsColumnContainer {
  display: flex;
}

.ComplainDetailsLeftContainer {
  flex: 0.5;
}

.ComplainDetailsRightContainer {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ComplaintDetailsDescription {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  color: #7c7f86;
}

.label {
  margin-left: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #7c7f86;
}

.radioCheck {
  margin: 10px 5px;
}
.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  font-family: Poppins;
  border: none;
}
.box {
  margin: 20px 0px;
  padding: 12px;
  border-radius: 5px;

  border: 1px solid #616066;
  background: transparent;
}
.box option {
  color: #7c7f86;
}
.dropdown {
  display: flex;
  margin: 10px 0px 10px 0px;
}
.dropvalue {
  padding: 10px;
  font-family: Poppins;
  font-size: 16px;
  opacity: 0.9;
  color: #7c7f86;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.005em;
}
.InputFieldContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 30px 0px 10px 0px;
}
.InputFieldContainer label {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.005em;
}
@media only screen and (max-width: 700px) {
  .ComplaintDetailsContainer {
    margin: 10px;
    font-size: 10px;
    padding: 10px;
  }
  .ComplaintDetailsColumnContainer {
    display: block;
  }
}
