.CreateComplaintContainerFooterButton {
  margin: 10px 150px 30px 10vw;
  display: flex;
  align-items: center;
  font-family: Poppins;
}

.CreateComplaintContainerNextButton {
  padding: 15px 30px;
  background-color: hsl(222, 4%, 51%);
  color: #fff;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  margin-right: 100px;
  cursor: pointer;
}

.Cancel {
  color: #7c7f86;
  cursor: pointer;
}
.icons {
  width: 40px;
  margin: 10px;
}
.CreateComplaintContainerSubmitButton {
  padding: 15px 30px;
  background-color: #407bff;
  color: #fff;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  margin-right: 100px;
  cursor: pointer;
}
.donepara {
  width: 60%;
  display: flex;
  margin-top: 20vh;
  margin-left: 10vw;
}
.CreateComplaintReadytogoNextButton {
  background-color: #407bff;
  padding: 15px 30px;

  color: #fff;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  margin-right: 100px;
  cursor: pointer;
}
.CreateComplaintContainerNextButton:hover {
  background-color: #407bff;
}
@media only screen and (max-width: 700px) {
  .donepara {
    width: 80%;
    font-size: 10px;
  }
  .CreateComplaintContainerSubmitButton {
    width: 100%;
    margin: 10px;
    font-size: 10px;
  }
  .CreateComplaintContainerNextButton {
    margin: 10px;
  }
  .CreateComplaintContainerFooterButton {
    margin: 0px;
  }
}
