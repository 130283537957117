.DashBordContainer {
  background-color: #f6f6f6;
  padding: 50px;
  height: 80%;
  min-height: 500px;
  font-family: Poppins;
}
.DashBoardGreetings {
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 10px;
}
.DashBoardGreetingsHello {
  font-size: 20px;
  line-height: 0.2rem;
}
.DashBoardGreetingsHello span {
  font-weight: bold;
}
.DashBoardGreetingsWelcome {
  font-size: 16px;
}
.DashBoardGreetingsInstruction {
  font-size: 14px;
  color: #b5b3c4;
}
.DashBoardFromContainer {
  width: 50%;
  max-width: 400px;
}
.DashBoardSubmitButton {
  padding: 10px 35px;
  border: none;
  border-radius: 5px;
  background-color: #2969e5;
  color: #fff;
  cursor: pointer;
}

.DashBoardMessage {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.005em;
}
@media only screen and (max-width: 700px) {
  .DashBordContainer {
    padding: 10px;
    margin: 0px;
    height: 100vh;
  }
  .DashBoardFromContainer {
    width: 100%;
  }
}
